import * as React from 'react';
import { graphql } from 'gatsby';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import StyledHero from '../components/StyledHero';
import Banner from '../components/Banner';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

const IndexPage = ({ data }) => {
  const { homePageTitle, homePageSubTitle } = data.directus.global;
  return (
    <Layout>
      <SEO title="Home" />
      <StyledHero home="true">
        <StyledHero
          home="true"
          img={
            data.directus.global.backgroundImage.imageFile.childImageSharp
              ?.fluid
          }
        />
        <Banner title={homePageTitle} info={homePageSubTitle}>
          <AniLink fade to="/bands" className="btn-white">
            explore bands
          </AniLink>
        </Banner>
      </StyledHero>
    </Layout>
  );
};

export const query = graphql`
  query hp {
    directus {
      global {
        homePageTitle
        homePageSubTitle
        backgroundImage {
          id
          imageFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1280) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
